<template>
  <div class="app">
    <nav>
      <div>
        <div class="title">
          <img alt="iKnoWatt" v-bind:src="getLogoBase64" />
        </div>
      </div>
      <div
        style="position: absolute;
    right: 10px;
    top: 0px;"
      >
        <button v-if="isUserSupport" class="btn nav-btn" v-on:click="ssoAccess">
          <font-awesome-icon icon="info" />
          <span class="d-none d-xl-inline-block" style="margin-left: 8px;"
            >Help Centre</span
          >
        </button>
        <button v-if="isLoggedIn" class="btn nav-btn" v-on:click="account">
          <font-awesome-icon icon="house-user" />
          <span class="d-none d-xl-inline-block" style="margin-left: 8px;"
            >My Account</span
          >
        </button>
        <button v-if="isLoggedIn" class="btn nav-btn" v-on:click="logout">
          <b-icon-arrow-bar-right>Logout</b-icon-arrow-bar-right>
          <span class="d-none d-xl-inline-block">Logout</span>
        </button>
        <b-button v-if="!isLoggedIn" class="btn nav-btn" router-link to="/login"
          >Login</b-button
        >
      </div>
    </nav>

    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import { BIconArrowBarRight } from "bootstrap-vue";
import { logoData } from "@/components/mixins/logo";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(fas);

export default {
  name: "defaultLayout",
  components: {
    BIconArrowBarRight,
    FontAwesomeIcon
  },
  mixins: [logoData],
  computed: {
    isAdmin() {
      return this.$store.state.auth.isAdmin;
    },
    utc() {
      return this.$store.state.auth.utc;
    },
    isUserSupport() {
      const user = this.$store.state.auth.user;

      if ("is_support" in user) {
        return user.is_support;
      }

      return false;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    env() {
      return process.env.VUE_APP_ENV;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    account() {
      this.$router.push("/").catch(() => {});
    },
    ssoAccess() {
      let resolvedRoute = this.$router.resolve({
        name: "access-sso"
      });
      window.open(resolvedRoute.href, "_blank");
    }
  }
};
</script>

<style scoped lang="scss">
.app {
  background-color: #f6f6f7;
  min-height: 100vh;
  padding-bottom: 60px;
}

.version {
  font-size: 0.8rem;
  text-align: left;
  padding-left: 15px;
  border-top: 1px solid #eee;
  padding-top: 10px;
  div {
    font-weight: bold;
    margin-top: 10px;
  }
}

nav {
  height: 80px;
  background-color: white;
  color: white;
  text-align: center;
  line-height: 70px;
  padding-left: 30px;
  font-size: 2rem;
  border-bottom: #ddd 1px solid;
  position: relative;
  font-family: "Open Sans", sans-serif;

  img {
    width: 200px;
  }

  .title {
    text-align: left;

    @media (min-width: 576px) {
      text-align: center;
    }
  }

  div {
    color: $deGreen;
    //line-height: 120px;
    font-size: 1.5rem;

    span.title {
      @media (min-width: 576px) {
        font-size: 2rem;
      }
    }
  }
}

.content {
}

.btn {
  &.nav-btn {
    border: 1px solid #8555ab;
    background-color: #6b3594;
    color: white;
    margin: 6px;
  }
  &.nav-btn:hover {
    background-color: #8555ab;
  }
}
</style>
